import React from 'react';
import { useRouter } from 'next/router';
import Link from 'components/misc/Link';
import blogMenu from 'constants/blogMenu';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import analytics from 'app/utils/analytics';
import addTrailingSlash from 'utils/addTrailingSlash';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';

const BlogItem = props => {
  const { item, isActive } = props;

  const formatTitleMixpanel = item.title.toLowerCase().replace(/\s/g, '-');

  return (
    <Link
      key={item.id}
      href={addTrailingSlash(item.link.href)}
      as={addTrailingSlash(item.link.as)}
      passHref
    >
      <NavigationMenu.Link
        onClick={() => {
          mixpanel.track(trackedEvents.NavbarDropdownClicked, {
            param: `blog-${formatTitleMixpanel}`,
          });
          analytics.trackMenuItemClick({
            title: 'Blog',
            menuItemName: formatTitleMixpanel,
          });
        }}
        className="w-full"
      >
        <div
          className={clsx(
            'flex items-center px-3 py-2 gap-4 w-full',
            isActive && 'bg-neutral-700',
            isActive ? 'hover:bg-neutral-700' : 'hover:bg-blue-100',
          )}
        >
          <img src={item.image} alt={item.title} height={24} width={24} />
          <p className={clsx(isActive && 'text-white')}>{item.title}</p>
        </div>
      </NavigationMenu.Link>
    </Link>
  );
};

const NavBlogList = () => {
  const router = useRouter();

  const dataSource = blogMenu.map(item => {
    const activeBlogSection = item.subcategories.some(
      subcategory => addTrailingSlash(subcategory.link.as) === router.asPath,
    );
    const activeBlog =
      addTrailingSlash(item.link.as) === router.asPath &&
      router.pathname.includes('/blog');

    return (
      <BlogItem
        key={item.id}
        item={item}
        isActive={activeBlogSection || activeBlog}
      />
    );
  });

  return <div className="list gap-1 items-start">{dataSource}</div>;
};

export default NavBlogList;
