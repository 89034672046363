import React from 'react';
import { useRouter } from 'next/router';
import { ChevronDown, ChevronUp } from 'react-feather';
import addTrailingSlash from 'app/utils/addTrailingSlash';
import clsx from 'clsx';

const DefaultAccordionItemButton = ({ item, expanded }) => {
  const router = useRouter();
  const isActive =
    item.subcategories.some(
      subcategory => addTrailingSlash(subcategory.link.as) === router.asPath,
    ) || addTrailingSlash(item.link?.as) === router.asPath;

  return (
    <div className="bg-white">
      <div
        className={clsx(
          'flex items-center justify-between py-3 px-4',
          isActive
            ? 'bg-neutral-700 text-neutral-100'
            : 'bg-neutral-100 text-neutral-700',
        )}
      >
        <div className="flex gap-2 items-center">
          {item.image && (
            <img src={item.image} alt="icon" width="25" height="25" />
          )}
          <p
            className={clsx(
              isActive ? 'text-neutral-100' : 'text-neutral-700',
              expanded && 'font-bold',
            )}
          >
            {item.title}
          </p>
        </div>
        {expanded ? (
          <ChevronUp size={20} className="text-neutral-500" />
        ) : (
          <ChevronDown size={20} className="text-neutral-500" />
        )}
      </div>
    </div>
  );
};

export default DefaultAccordionItemButton;
