import styled from 'styled-components';
import spacing from '../themes/spacing';
import borderRadius from '../themes/borderRadius';
import colors from '../themes/colors';
import Space from '../space';

export const StyledList = styled.div`
  width: max-content;
  position: absolute;
  border-radius: ${props => props.theme.borderRadius.lg};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 1;
  outline: none;
  margin-top: ${spacing.xxs};
  right: ${props => (props.menuAlign === 'right' ? '0px' : 'unset')};
  left: ${props => (props.menuAlign === 'left' ? '0px' : 'unset')};
  bottom: ${props => (props.menuAlign === 'top' ? '100%' : 'unset')};
  max-height: 576px;
  overflow: ${props => (props.noOverflow ? 'unset' : 'auto')};
  display: ${props => (props.showList ? 'flex' : 'none')};
  flex-direction: column;

  z-index: 100;

  > a:last-child {
    margin-bottom: ${props => props.theme.spacing.xs};
    border-radius: ${props => props.theme.borderRadius.md};
  }

  > a:first-child {
    margin-top: ${props => props.theme.spacing.xs};
    border-radius: ${props => props.theme.borderRadius.md};
  }

  > a {
    padding: 8px 14px 8px 14px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    background-color: ${props => props.isActive && props.theme.colors.neutral7};
    &:hover {
      background-color: ${props => props.theme.colors.blue1};
      border-radius: 0;
    }
  }
`;

export const StyledSelectList = styled.ul`
  width: ${props => props.width || 'fit-content'};
  position: absolute;
  border-radius: ${borderRadius.md};
  box-shadow: 0 2px 4px 0 rgba(64, 72, 90, 0.5);
  background-color: white;
  z-index: 1;
  outline: none;
  margin-top: ${spacing.xs};
  right: ${props => (props.menuAlign === 'right' ? '0px' : 'unset')};
  left: ${props => (props.menuAlign === 'left' ? '0px' : 'unset')};
  bottom: ${props => (props.menuAlign === 'top' ? '100%' : 'unset')};
  padding: ${props => props.isOpen && spacing.xxs};
  max-height: 300px;
  overflow: auto;
  z-index: 100;
`;

export const SelectListItem = styled.li`
  cursor: pointer;
  width: 100%;
  white-space: nowrap;

  background-color: ${props =>
    props.isSelected ? colors.neutral7 : colors.neutral1};
  border-radius: ${borderRadius.md};
  padding: ${spacing.xs} ${spacing.sm};

  &:hover {
    background-color: ${props =>
      props.isSelected ? colors.neutral7 : colors.blue1};
  }
`;

export const ButtonWrapper = styled.div`
  cursor: pointer;
  width: fit-content;
`;

export const SelectButtonWrapper = styled.div`
  cursor: pointer;
  width: ${props => props.buttonWidth || 'fit-content'};
  border: ${props => props.variant.border};
  border-radius: 4px;
  padding: ${props => props.padding};
  background-color: ${props =>
    props.isOpen
      ? props.variant.backgroundColorOpened
      : props.variant.backgroundColor};

  p {
    color: ${props =>
      props.isOpen
        ? props.variant.contentColorOpened
        : props.variant.contentColor};
  }

  svg {
    stroke: ${props =>
      props.isOpen
        ? props.variant.contentColorOpened
        : props.variant.contentColor};
  }

  &:hover {
    background-color: ${props =>
      props.isOpen
        ? props.variant.backgroundColorHoveredOpened
        : props.variant.backgroundColorHovered};

    p {
      color: ${props =>
        props.isOpen
          ? props.variant.contentColorHoveredOpened
          : props.variant.contentColorHovered};
    }

    svg {
      stroke: ${props =>
        props.isOpen
          ? props.variant.contentColorHoveredOpened
          : props.variant.contentColorHovered};
    }
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  width: ${props => (props.fullWidth ? '100%' : 'fit-content')};
`;

// for profile select
export const StyledProfileList = styled.ul`
  min-width: 240px;
  max-width: 320px;
  max-height: 400px;
  position: absolute;
  border-radius: ${borderRadius.md};
  box-shadow: 0 2px 4px 0 rgba(64, 72, 90, 0.5);
  background-color: white;
  z-index: 1;
  outline: none;
  overflow-y: scroll;
  margin-top: ${spacing.xs};
  right: ${props => (props.menuAlign === 'right' ? '0px' : 'unset')};
  left: ${props => (props.menuAlign === 'left' ? '0px' : 'unset')};
  z-index: 100;

  > li:last-child {
    padding-bottom: ${spacing.xs};
  }

  p {
    white-space: normal;
  }
`;

export const TopLabel = styled.div`
  padding: ${spacing.sm};
  padding-bottom: 0px;
`;

export const SectionLabel = styled.div`
  padding: ${spacing.sm};
  padding-bottom: ${spacing.xs};
`;

export const StyledProfileListItem = styled.li`
  padding-left: ${spacing.sm};
  padding-right: ${spacing.sm};
  padding-bottom: ${spacing.xs};
  padding-top: ${spacing.xs};
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  background: ${props => (props.isSelected ? colors.neutral7 : 'white')};

  &:hover {
    background: ${props =>
      props.isSelected ? colors.neutral7 : colors.neutral3};
  }
`;

export const LinkContainer = styled(Space)`
  height: 40px;
  padding: ${props => `${props.theme.spacing.none} ${props.theme.spacing.sm}`};
  position: relative;

  ::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 1px;
    border-bottom: ${props =>
      props.isActive && `2px solid ${props.theme.colors.seedlyBlue}`};
    height: 100%;
    width: 100%;
  }

  p {
    color: ${props => props.isActive && props.theme.colors.seedlyBlue};
  }

  :hover {
    background-color: ${props => props.theme.colors.blue1};
    cursor: pointer;
    border-radius: 8px;
    border-bottom: 0;

    p {
      color: ${props => props.theme.colors.seedlyBlue};
    }
  }
`;
