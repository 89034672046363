import React, { useState, useContext } from 'react';
import { Menu } from 'react-feather';
import { PageContext, PathContext } from 'app/context';
import { Colors } from 'app/seedly-component-library';
import { MOBILE_TOP_NAV } from 'app/constants/navBar';
import PrimaryMenu from './PrimaryMenu';
import { CommunityMenu, BlogMenu, ReviewMenu, MoreMenu } from './SecondaryMenu';

import * as S from './styles';

const getDefaultMenu = (host, path) => {
  const { COMMUNITY_MENU, BLOG_MENU, REVIEW_MENU, MORE_MENU } = MOBILE_TOP_NAV;
  if (!path) {
    return COMMUNITY_MENU;
  }

  if (path.startsWith('/reviews')) {
    return REVIEW_MENU;
  }

  if (path.startsWith('/community')) {
    return COMMUNITY_MENU;
  }

  if (path.startsWith('/c') || path.startsWith('/rewards')) {
    return MORE_MENU;
  }

  if (host.indexOf('blog') !== -1) {
    return BLOG_MENU;
  }

  return COMMUNITY_MENU;
};

const MobileMenu = () => {
  const { COMMUNITY_MENU, BLOG_MENU, REVIEW_MENU, MORE_MENU } = MOBILE_TOP_NAV;
  const { setScrollable } = useContext(PageContext);
  const { host, path } = useContext(PathContext);
  const defaultMenu = getDefaultMenu(host, path);
  const [isOpen, toggleMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(defaultMenu);

  const handleClick = () => {
    toggleMenu(prevState => !prevState);
    setScrollable(isOpen);
  };

  let menu;
  switch (activeMenu) {
    case COMMUNITY_MENU:
      menu = <CommunityMenu onCloseClick={handleClick} />;
      break;
    case BLOG_MENU:
      menu = <BlogMenu onItemClick={handleClick} />;
      break;
    case REVIEW_MENU:
      menu = <ReviewMenu onItemClick={handleClick} />;
      break;
    case MORE_MENU:
      menu = <MoreMenu onItemClick={handleClick} />;
      break;
    default:
      return <CommunityMenu onCloseClick={handleClick} />;
  }

  return (
    <S.Container>
      <S.MenuButton onClick={() => handleClick()}>
        <Menu size={22} color={Colors.neutral7} />
      </S.MenuButton>
      <S.Body open={isOpen} id="Sidebar">
        <S.BodyInner>
          <PrimaryMenu
            activeMenu={activeMenu}
            onCloseClick={handleClick}
            onNavClick={active => setActiveMenu(active)}
          />
          <div>{menu}</div>
        </S.BodyInner>
      </S.Body>
    </S.Container>
  );
};

export default MobileMenu;
