import React, { useEffect, useState, useRef } from 'react';
import Link from 'app/components/misc/Link';
import { Settings as SettingsIcon } from 'react-feather';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import Spinner from 'seedly-component-library/spinner-new';
import useClickOutside from 'utils/hooks/useClickOutside';
import clsx from 'clsx';
import useGetNotifications from './hooks/useGetNotifications';
import Menu from './Menu';
import NotificationList from './List';
import Tabs from './Tabs';

const Notifications = () => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentTab, setTab] = useState('All');
  const { notification, isFetching, fetchNotification } = useGetNotifications();
  const { meta } = notification;
  const { checkedAt } = meta;

  const handleTabClick = tab => {
    mixpanel.track(trackedEvents.NotificationClicked, {
      param: tab,
    });
    setTab(tab);
  };

  const toggleDropdown = async () => {
    if (!isOpen) {
      mixpanel.track(trackedEvents.NotificationClicked);
      mixpanel.track(trackedEvents.NavbarClicked, {
        param: 'notification',
      });
    }
    setIsOpen(!isOpen);
  };

  useClickOutside(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen) {
      let tab;
      if (currentTab === 'Activity') tab = 'activity';
      if (currentTab === 'Updates') tab = 'updates';
      fetchNotification(tab);
    }
  }, [isOpen, fetchNotification, currentTab]);

  return (
    <div className="relative" ref={dropdownRef}>
      <Menu onClick={toggleDropdown} />
      {isOpen && (
        <div
          className={clsx(
            'absolute w-[360px] max-w-[360px] max-h-[576px] z-10 bg-white right-0 top-[46px] rounded-md shadow-xl',
            isOpen ? 'block' : 'none',
          )}
          data-testid="notification_dropdown"
        >
          <div className="flex flex-col w-full">
            <div className="flex justify-between py-4 px-6 w-full">
              <p className="text-base font-bold">Notifications</p>
              <Link href="/settings">
                <a
                  onClick={() => {
                    mixpanel.track(trackedEvents.NotificationClicked, {
                      param: 'setting',
                    });
                  }}
                >
                  <SettingsIcon className="h-5 w-5 cursor-pointer ml-auto text-neutral-500" />
                </a>
              </Link>
            </div>
            <div className="flex pb-4 px-6 pt-0 gap-2">
              <Tabs currentTab={currentTab} handleTabClick={handleTabClick} />
            </div>
          </div>
          {isFetching ? (
            <div className="justify-center items-center text-center p-6">
              <Spinner />
            </div>
          ) : (
            <NotificationList
              notification={notification}
              checkedAt={checkedAt}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
