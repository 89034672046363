import React from 'react';
import { X as CloseIcon } from 'react-feather';
import { MOBILE_TOP_NAV } from 'app/constants/navBar';
import { imageUrl } from 'theme';
import { Text } from '@component-lib';
import CommunityFilled from 'seedly-component-library/icons/community-filled.svg';
import ThreeDotFilled from 'seedly-component-library/icons/three-dot-filled.svg';
import ReviewFilled from 'seedly-component-library/icons/review-filled.svg';
import BlogFilled from 'seedly-component-library/icons/blog-filled.svg';

import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import clsx from 'clsx';
import Logo from '../../Logo';

import * as S from './styles';

const { COMMUNITY_MENU, BLOG_MENU, REVIEW_MENU, MORE_MENU } = MOBILE_TOP_NAV;

const menuData = [
  {
    url: imageUrl.community,
    nav: COMMUNITY_MENU,
    filledSVG: <CommunityFilled />,
    dataName: 'community',
  },
  {
    url: imageUrl.blog,
    nav: BLOG_MENU,
    filledSVG: <BlogFilled />,
    dataName: 'blog',
  },
  {
    url: imageUrl.reviews,
    nav: REVIEW_MENU,
    filledSVG: <ReviewFilled />,
    dataName: 'product_reviews',
  },
  {
    url: imageUrl.more,
    nav: MORE_MENU,
    filledSVG: <ThreeDotFilled />,
    dataName: 'more',
  },
];

const formatMenuTitle = activeMenu => {
  const menuTitle = {
    [COMMUNITY_MENU]: 'Community',
    [BLOG_MENU]: 'Blog',
    [REVIEW_MENU]: 'Product Reviews',
    [MORE_MENU]: 'More',
  };

  return menuTitle[activeMenu];
};

const PrimaryMenu = ({ onCloseClick, activeMenu, onNavClick }) => {
  const renderMenu = menuData.map(data => {
    return (
      <div
        key={data.url}
        onClick={() => {
          mixpanel.track(trackedEvents.NavbarClicked, {
            param: data.dataName,
          });
          onNavClick(data.nav);
        }}
        className={clsx(
          'w-[calc(100%/3)] flex justify-center py-3',
          data.nav === activeMenu && 'border-b-2 border-blue-500',
        )}
      >
        {data.nav === activeMenu ? (
          <div>{data.filledSVG}</div>
        ) : (
          <img alt="tab-icon" src={data.url} height={24} width={24} />
        )}
      </div>
    );
  });

  return (
    <S.PrimaryMenu>
      <S.Header>
        <Logo height={24} color="#ffffff" />
        <S.CloseButton onClick={() => onCloseClick()}>
          <CloseIcon color="#ffffff" />
        </S.CloseButton>
      </S.Header>
      <S.Menu>{renderMenu}</S.Menu>
      <S.SubMenu>
        <Text variant="titlelg">{formatMenuTitle(activeMenu)}</Text>
      </S.SubMenu>
    </S.PrimaryMenu>
  );
};

export default PrimaryMenu;
