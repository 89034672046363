import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import DefaultAccordionItemButton from './DefaultAccordionItemButton';
import DefaultAccordionItemPanel from './DefaultAccordionItemPanel';

const AccordionContainer = styled.div`
  margin-top: ${props => props.theme.spacing.xs};
  width: 100%;
  background-color: #ffffff;
`;

const AccordionItemButton = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-family: Open Sans;
  padding: 0;
  margin: 0;
  outline: none;
`;

const Accordion = ({
  items,
  renderItemButton,
  renderItemPanel,
  multipleExpandedDisabled,
  onItemClick = noop,
}) => {
  if (!items.length) return null;
  const [itemExpanded, setItemExpanded] = React.useState({});

  const handleOnClick = index => {
    let nextState = {
      ...itemExpanded,
      [index]: !itemExpanded[index],
    };
    if (multipleExpandedDisabled && !itemExpanded[index]) {
      nextState = { [index]: !itemExpanded[index] };
    }
    setItemExpanded(nextState);
  };
  return (
    <AccordionContainer>
      {items.map((item, index) => {
        const expanded = Boolean(itemExpanded[index]);
        const itemProps = {
          item,
          index,
          expanded,
          onItemClick,
        };
        return (
          <>
            <AccordionItemButton onClick={() => handleOnClick(index)}>
              {renderItemButton(itemProps)}
            </AccordionItemButton>
            {itemExpanded[index] && renderItemPanel(itemProps)}
          </>
        );
      })}
    </AccordionContainer>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  renderItemButton: PropTypes.func,
  renderItemPanel: PropTypes.func,
  multipleExpandedDisabled: PropTypes.bool,
};

Accordion.defaultProps = {
  items: [],
  multipleExpandedDisabled: false,
  renderItemButton: props => <DefaultAccordionItemButton {...props} />,
  renderItemPanel: props => <DefaultAccordionItemPanel {...props} />,
};

export default Accordion;
