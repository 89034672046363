import { useState, useEffect } from 'react';
import notificationApi from 'entity/notification/notificationApi';

function useGetNotificationCount() {
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    async function fetchCount() {
      const {
        data: response,
        ok,
      } = await notificationApi.getNotificationCount();

      if (!ok) return;

      setNotificationCount(response.data.new_count);
    }

    fetchCount();
  }, []);

  return { notificationCount, setNotificationCount };
}

export default useGetNotificationCount;
