import { useSelector } from 'react-redux';
import { RootState } from 'reducer';
import { Selector, createSelector } from 'reselect';
import { NavigationCampaign } from './navigationRedux';

const getIds: Selector<RootState, number[]> = (state: RootState) =>
  state.navigation.campaigns.ids;

const getById: Selector<RootState, Record<number, NavigationCampaign>> = (
  state: RootState,
) => state.navigation.campaigns.byId;

const campaignSelectorByCategory = (category: string) =>
  createSelector([getIds, getById], (ids, byId) => {
    return ids
      .map(id => byId[id])
      .filter(
        campaign => campaign.isFeatured && campaign.category === category,
      );
  });

const useSelectLatestCampaigns = () => {
  return useSelector(campaignSelectorByCategory('Latest Happenings'));
};

const useSelectOtherCampaigns = () => {
  return useSelector(campaignSelectorByCategory('Others'));
};

export { useSelectLatestCampaigns, useSelectOtherCampaigns };
