import React from 'react';
import Spinner from '../icons/spinner.svg';

const SpinnerWrapper = () => {
  return (
    <Spinner className="inline mr-2 w-8 h-8 animate-spin text-blue-200 fill-blue-500" />
  );
};

export default SpinnerWrapper;
