import React, { useState } from 'react';
import clsx from 'clsx';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { ChevronDown } from 'react-feather';

const NavigationTrigger = props => {
  const { isActive, title, activeIcon, hoverIcon, icon } = props;

  const [isHover, setHover] = useState(false);

  let navIcon = isHover ? hoverIcon : icon;
  if (isActive) navIcon = activeIcon;

  return (
    <NavigationMenu.Trigger
      className={clsx(
        'relative h-10 px-3 hover:bg-blue-100 hover:text-blue-500 rounded-lg',
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex items-center">
        {navIcon}
        <p className={clsx(isActive && 'text-blue-500', navIcon && 'pl-2')}>
          {title}
        </p>
        <ChevronDown
          size={24}
          className={clsx(
            isActive || isHover ? 'text-blue-500' : 'text-neutral-700',
            'pl-1',
          )}
        />
      </div>
      <div
        className={clsx(
          isActive && 'absolute border-b-2 border-blue-500 mt-4 left-0 w-full',
        )}
      />
    </NavigationMenu.Trigger>
  );
};

export default NavigationTrigger;
