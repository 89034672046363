import React, { useContext } from 'react';
import { useIsSignedIn } from 'entity/user/userHooks';
import { CommunityPageContext } from 'pageContents/CommunityContent/context/CommunityPageContext';
import { Compass } from 'react-feather';
import clsx from 'clsx';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import analytics from 'app/utils/analytics';
import {
  useSelectFollowedGroupListByUnreadCount,
  useSelectGroupList,
} from 'entity/group/groupHooks';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import Link from 'components/misc/Link';
import Avatar from 'seedly-component-library/avatar-new';
import ListWrapper from './ListWrapper';

const MAX_GROUPS_SIZE = 5;

const NavGroupList = ({ onItemClick = () => {}, isMobile = false }) => {
  const followedGroups = useSelectFollowedGroupListByUnreadCount();
  const trendingGroups = useSelectGroupList();
  const isSignedin = useIsSignedIn();
  const { groupId, pageType, setPageType } = useContext(CommunityPageContext);

  const Groups = ({ isActive, group }) => (
    <div
      key={group.id}
      onClick={() => {
        mixpanel.track(trackedEvents.NavbarDropdownClicked, {
          param: `community-${group.slug}`,
        });
        analytics.trackMenuItemClick({
          title: 'Community',
          menuItemName: group.slug,
        });
        onItemClick();
      }}
      className="w-full"
    >
      <Link href={`/community/${group.slug}/`} passHref>
        {isMobile ? (
          <a>
            <ListWrapper
              icon={<Avatar src={group.profileImage} sizeClass="avatar-6" />}
              isActive={isActive}
              unreadCount={group.unreadCount}
              name={group.name}
            />
          </a>
        ) : (
          <NavigationMenu.Link>
            <ListWrapper
              icon={<Avatar src={group.profileImage} sizeClass="avatar-6" />}
              isActive={isActive}
              unreadCount={group.unreadCount}
              name={group.name}
            />
          </NavigationMenu.Link>
        )}
      </Link>
    </div>
  );

  let dataSource = [];

  if (!isSignedin) {
    dataSource = trendingGroups.slice(0, MAX_GROUPS_SIZE).map(group => {
      const isActive = groupId === group.id && pageType === 'group';
      return <Groups key={group.id} isActive={isActive} group={group} />;
    });
  } else {
    dataSource = followedGroups.map(group => {
      const isActive = groupId === group.id && pageType === 'group';
      return <Groups key={group.id} isActive={isActive} group={group} />;
    });
  }

  dataSource.unshift(
    <div
      style={{ cursor: 'pointer' }}
      key="discover"
      onClick={() => {
        onItemClick();
        setPageType('discover-groups');
      }}
      className="w-full"
    >
      <Link href="/community/?discover=true" passHref>
        {isMobile ? (
          <a>
            <ListWrapper
              isActive={pageType === 'discover-groups'}
              icon={
                <Compass
                  className={clsx(
                    pageType === 'discover-groups'
                      ? 'text-neutral-100'
                      : 'text-neutral-700',
                  )}
                />
              }
              name="Discover Groups"
            />
          </a>
        ) : (
          <NavigationMenu.Link>
            <ListWrapper
              isActive={pageType === 'discover-groups'}
              icon={
                <Compass
                  className={clsx(
                    pageType === 'discover-groups'
                      ? 'text-neutral-100'
                      : 'text-neutral-700',
                  )}
                />
              }
              name="Discover Groups"
            />
          </NavigationMenu.Link>
        )}
      </Link>
    </div>,
  );

  return (
    <div className="flex flex-col w-full gap-2">
      {isMobile ? null : (
        <p className="pl-4 pt-4 text-neutral-500 text-xs font-bold">
          {isSignedin ? 'MY GROUPS' : 'TRENDING GROUPS'}
        </p>
      )}
      <div className="list gap-1">{dataSource}</div>
    </div>
  );
};

export default NavGroupList;
