import React from 'react';
import { CommunityPageType } from '../context/CommunityPageContext';

// NOTE: this is not really a hook but just temperory named like this so multiple place can use.
interface FeedList {
  name: string;
  value: CommunityPageType;
  icon: React.ReactNode;
  query?: string;
  trackingParam?: string;
}

const useFeedList = (): FeedList[] => {
  return [
    {
      name: 'Home',
      value: 'home',
      icon: (
        <span role="img" aria-label="home">
          🏠
        </span>
      ),
      trackingParam: 'home',
    },
    {
      name: 'Trending',
      value: 'trending',
      icon: (
        <span role="img" aria-label="trend">
          💫
        </span>
      ),
      query: '?trending=true',
      trackingParam: 'trending',
    },
    {
      name: 'Articles',
      value: 'article',
      icon: (
        <span role="img" aria-label="article">
          📰
        </span>
      ),
      query: '?articles=true',
      trackingParam: 'articles',
    },
  ];
};

export default useFeedList;
