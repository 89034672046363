import styled from 'styled-components';

export const PrimaryMenu = styled.div`
  width: 100vw;
  height: fit-content;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: sticky;
  top: 0;
  background: white;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing.md};
  background-color: ${props => props.theme.colors.seedlyBlue};
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 16px;
  cursor: pointer;
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.neutral4};
  border-bottom: 1px solid ${props => props.theme.colors.neutral4};
`;

export const SubMenu = styled.div`
  padding-top: ${props => props.theme.spacing.sm};
  padding-bottom: ${props => props.theme.spacing.sm};
  padding-left: ${props => props.theme.spacing.md};
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.neutral4};
`;

export const MenuItem = styled.li`
  width: calc(100% / 3);
  text-align: center;
  padding: ${props => `${props.theme.spacing.sm} ${props.theme.spacing.none}`};
`;

export const Title = styled.p`
  font-size: 16px;
  font-family: 'Open Sans';
  line-height: 22px;
  font-weight: bold;
  border-right: 1px solid ${props => props.theme.colors.neutral4};
`;

export const Desc = styled.div`
  font-size: 12px;
`;
