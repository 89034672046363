import React from 'react';
import ReviewItemAccordion from 'app/components/review/ReviewItemAccordion';
import reviewItem from 'app/constants/reviews';

const ReviewMenu = props => {
  const { onItemClick } = props;
  return <ReviewItemAccordion onItemClick={onItemClick} items={reviewItem} />;
};

export default ReviewMenu;
