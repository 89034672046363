import React from 'react';
import { useRouter } from 'next/router';
import he from 'he';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import Link from 'app/components/misc/Link';
import analytics from 'app/utils/analytics';
import { NAV_BAR_CATEGORY_TITLE } from 'app/constants/navBar';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import addTrailingSlash from 'app/utils/addTrailingSlash';
import DefaultAccordionItemButton from './Accordion/DefaultAccordionItemButton';
import Accordion from './Accordion';

const CategoryItem = props => {
  const { item, onItemClick } = props;
  const router = useRouter();
  const isActive =
    router.asPath === addTrailingSlash(item.link.as) &&
    router.pathname.includes('/blog');
  const formatTitle = item.title.replace(/\s+/g, '-').toLowerCase();

  return (
    <>
      {isEmpty(item.subcategories) ? (
        <Link href={item.link.href} as={item.link.as} passHref>
          <a
            className={clsx(
              'block text-left py-3 px-4',
              isActive
                ? 'bg-neutral-700 text-neutral-100'
                : 'bg-neutral-100 text-neutral-700',
            )}
            onClick={() => {
              onItemClick();
              analytics.trackNavbarItemClick({
                url: `/blog${item.link.as}`,
                subCategoryName: NAV_BAR_CATEGORY_TITLE.READ,
              });
              mixpanel.track(trackedEvents.NavbarDropdownClicked, {
                param: `blog-${formatTitle}`,
              });
            }}
            isActive={isActive}
          >
            <div className="flex gap-2 items-center">
              <img src={item.image} alt="icon" width="25" height="25" />
              <p className={clsx(isActive ? 'text-white' : 'text-neutral-700')}>
                {he.decode(item.title)}
              </p>
            </div>
          </a>
        </Link>
      ) : (
        <DefaultAccordionItemButton {...props} />
      )}
    </>
  );
};

const SubcategoriesPanel = props => {
  const { subcategories, link, title } = props.item;
  const formatTitle = title.replace(/\s+/g, '-').toLowerCase();

  const { onItemClick } = props;
  if (isEmpty(subcategories)) return null;
  return (
    <div className="bg-neutral-100 flex flex-col">
      {subcategories.map((sc, index) => (
        <Link key={index} passHref href={sc.link.href} as={sc.link.as}>
          <a
            onClick={() => {
              onItemClick();
              analytics.trackNavbarItemClick({
                url: `/blog${sc.link.as}`,
                subCategoryName: NAV_BAR_CATEGORY_TITLE.READ,
              });
            }}
            className="p-2.5 pl-[56px]"
          >
            {sc.title}
          </a>
        </Link>
      ))}
      <Link passHref href={link.href} as={link.as}>
        <a
          onClick={() => {
            onItemClick();
            mixpanel.track(trackedEvents.NavbarDropdownClicked, {
              param: `blog-${formatTitle}`,
            });
          }}
          className="p-2.5 pl-[56px]"
        >
          View All
        </a>
      </Link>
    </div>
  );
};

const BlogCategoryAccordion = ({ items, onItemClick }) => {
  return (
    <Accordion
      items={items}
      onItemClick={onItemClick}
      renderItemButton={CategoryItem}
      renderItemPanel={SubcategoriesPanel}
      multipleExpandedDisabled
    />
  );
};

export default BlogCategoryAccordion;
