import React from 'react';
import { useRouter } from 'next/router';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import Blog from 'seedly-component-library/icons/blog.svg';
import BlogFilled from 'seedly-component-library/icons/blog-filled.svg';
import NavigationTrigger from '../wrapper/NavigationTrigger';
import NavigationContent from '../wrapper/NavigationContent';
import NavBlogList from '../../NavList/NavBlogList';

const BlogTab = () => {
  const slug = ['/blog', '/blog/category'];
  const router = useRouter();
  const isActive = slug.includes(router.pathname);

  return (
    <NavigationMenu.Item>
      <NavigationTrigger
        isActive={isActive}
        icon={<Blog />}
        hoverIcon={<Blog className="stroke-blue-500" />}
        activeIcon={<BlogFilled />}
        title="Blog"
      />
      <NavigationContent className="w-max overflow-y-hidden">
        <NavBlogList />
      </NavigationContent>
    </NavigationMenu.Item>
  );
};

export default BlogTab;
