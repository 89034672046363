import React from 'react';
import useIsMobile from 'app/utils/hooks/useIsMobile';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { Bell } from 'react-feather';
import useGetNotificationCount from './hooks/useGetNotificationCount';

const Menu = ({ onClick }) => {
  const isMobile = useIsMobile();
  const router = useRouter();
  const { notificationCount, setNotificationCount } = useGetNotificationCount();

  const handleOnClick = () => {
    setNotificationCount(0);
    if (isMobile) return router.push('/notifications');
    onClick();
  };

  return (
    <div
      className="inline-block cursor-pointer w-auto"
      data-testid="notification"
      onClick={handleOnClick}
    >
      <div
        className={clsx(
          'absolute bg-red-500 text-center text-white flex items-center justify-center h-5 w-5 left-3 text-xs rounded font-bold',
          notificationCount > 0 ? 'visible' : 'hidden',
        )}
        data-testid="notification_count"
      >
        {notificationCount}
      </div>
      <Bell className="text-black h-6 w-auto cursor-pointer mt-1.5" />
    </div>
  );
};

export default Menu;
