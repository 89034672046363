import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { Colors, Text } from 'app/seedly-component-library';
import Link from 'app/components/misc/Link';
import Accordion from 'app/components/blog/BlogCategoryAccordion/Accordion';
import DefaultAccordionItemButton from 'app/components/blog/BlogCategoryAccordion/Accordion/DefaultAccordionItemButton';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';

const ReviewItem = props => {
  const { item, onItemClick } = props;

  return (
    <>
      {isEmpty(item.subcategories) ? (
        <Link href="/reviews" as="/reviews" passHref>
          <CategoryLink
            onClick={() => {
              onItemClick();
              mixpanel.track(trackedEvents.NavbarDropdownClicked, {
                param: `product_reviews-view_all`,
              });
            }}
          >
            <Text>View All</Text>
          </CategoryLink>
        </Link>
      ) : (
        <DefaultAccordionItemButton {...props} />
      )}
    </>
  );
};

const SubcategoriesPanel = props => {
  const { subcategories, title } = props.item;
  const reviewMainCategoryTitle = title.replace(/\s+/g, '-').toLowerCase();
  const { onItemClick } = props;

  return (
    <SubCategoryContainer>
      {subcategories.map(category => {
        const formatTitle = category.title.replace(/\s+/g, '_').toLowerCase();
        return (
          <Link
            key={category.link.href}
            passHref
            href={category.link.href}
            as={category.link.as}
          >
            <a
              onClick={() => {
                onItemClick();
                mixpanel.track(trackedEvents.NavbarDropdownClicked, {
                  param: `product_reviews-${reviewMainCategoryTitle}-${formatTitle}`,
                });
              }}
            >
              {category.title}
            </a>
          </Link>
        );
      })}
    </SubCategoryContainer>
  );
};

const ReviewItemAccordion = ({ items, onItemClick }) => {
  return (
    <Accordion
      items={items}
      onItemClick={onItemClick}
      renderItemButton={ReviewItem}
      renderItemPanel={SubcategoriesPanel}
      multipleExpandedDisabled
    />
  );
};

export default ReviewItemAccordion;

const SubCategoryContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.sm};
  background-color: #ffffff;
  color: ${Colors.neutral7};
  a {
    display: block;
    padding: 10px;
    padding-left: ${props => props.theme.spacing.lg};
    :last-child {
      padding-bottom: 0;
    }
  }
`;

const CategoryLink = styled.a`
  background-color: ${props => props.theme.colors.neutral1};
  text-align: left;
  padding: ${props => `${props.theme.spacing.sm} ${props.theme.spacing.md}`};
  display: block;
`;
