import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Body = styled.div`
  position: fixed;
  transform: translateX(-100%);
  left: 0;
  right: auto;
  top: 0;
  transition: 0.75s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 3;
  ${props => props.open && ` transform: translate(0, 0);`};
  background: white;
  height: 100vh;

  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
`;

export const BodyInner = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;
`;

export const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
`;
