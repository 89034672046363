import React from 'react';
import BlogCategoryAccordion from 'app/components/blog/BlogCategoryAccordion';
import blogMenu from 'app/constants/blogMenu';

const BlogMenu = props => {
  const { onItemClick } = props;
  return <BlogCategoryAccordion onItemClick={onItemClick} items={blogMenu} />;
};

export default BlogMenu;
