import clsx from 'clsx';
import React from 'react';

interface OwnProps {
  children: React.ReactNode;
  className?: string;
  tier: number;
}

const TierColorContainer = (props: OwnProps) => {
  const { children, className, tier = 0 } = props;

  return (
    <div
      className={clsx(
        {
          'bg-purple-200': tier === 0 || tier === 1,
          'bg-orange-100': tier === 2,
          'bg-blue-500': tier === 3,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default TierColorContainer;
