export default [
  {
    title: 'Banking',
    subcategories: [
      {
        link: {
          href: '/reviews/savings-accounts',
          slug: 'savings-accounts',
        },
        title: 'Savings Accounts',
      },
      {
        link: {
          href: '/reviews/priority-banking',
          slug: '/priority-banking',
        },
        title: 'Priority Banking',
      },
      {
        link: {
          href: '/reviews/personal-loans',
          slug: 'personal-loans',
        },
        title: 'Personal Loans',
      },
    ],
  },
  {
    title: 'Investments',
    subcategories: [
      {
        link: {
          href: '/reviews/online-brokerages',
          slug: 'online-brokerages',
        },
        title: 'Brokerages',
      },
      {
        link: {
          href: '/reviews/robo-advisors',
          slug: 'robo-advisors',
        },
        title: 'Robo-Advisors',
      },
      {
        link: {
          href: '/reviews/investment-courses',
          slug: 'investment-courses',
        },
        title: 'Investments Courses',
      },
      {
        link: {
          href: '/reviews/p2p-lending',
          slug: 'p2p-lending',
        },
        title: 'P2P Lending',
      },
      {
        link: {
          href: '/reviews/crypto-exchange',
          slug: 'crypto-exchange',
        },
        title: 'Crypto Exchanges',
      },
      {
        link: {
          href: '/reviews/crypto-earn',
          slug: 'crypto-earn',
        },
        title: 'Crypto Earn',
      },
    ],
  },
  {
    title: 'Cards',
    subcategories: [
      {
        link: {
          href: '/reviews/credit-cards',
          slug: 'credit-cards',
        },
        title: 'Credit Cards',
      },
      {
        link: {
          href: '/reviews/debit-cards',
          slug: 'debit-cards',
        },
        title: 'Debit Cards',
      },
      {
        link: {
          href: '/reviews/multi-currency-cards',
          slug: 'multi-currency-cards',
        },
        title: 'Multi Currency Cards',
      },
    ],
  },
  {
    title: 'Utilities & Bills',
    subcategories: [
      {
        link: {
          href: '/reviews/open-electricity-market',
          slug: 'open-electricity-market',
        },
        title: 'Electricity Retailers',
      },
      {
        link: {
          href: '/reviews/sim-only-mobile-plans',
          slug: 'sim-only-mobile-plans',
        },
        title: 'SIM-Only Plans',
      },
      {
        link: {
          href: '/reviews/broadband',
          slug: 'broadband',
        },
        title: 'Broadband Plans',
      },
    ],
  },
  {
    title: 'Insurance',
    subcategories: [
      {
        link: {
          href: '/reviews/travel-insurance',
          slug: 'travel-insurance',
        },
        title: 'Travel Insurance',
      },
      {
        link: {
          href: '/reviews/health-insurance',
          slug: 'health-insurance',
        },
        title: 'Health Insurance',
      },
      {
        link: {
          href: '/reviews/personal-accident-insurance',
          slug: 'personal-accident-insurance',
        },
        title: 'Personal Accident Insurance',
      },
      {
        link: {
          href: '/reviews/life-insurance',
          slug: 'life-insurance',
        },
        title: 'Life Insurance',
      },
      {
        link: {
          href: '/reviews/disability-insurance',
          slug: 'disability-insurance',
        },
        title: 'Disability Insurance',
      },
      {
        link: {
          href: '/reviews/cancer-insurance',
          slug: 'cancer-insurance',
        },
        title: 'Cancer Insurance',
      },
      {
        link: {
          href: '/reviews/insurance-savings-plans',
          slug: 'insurance-savings-plans',
        },
        title: 'Insurance Savings Plans',
      },
      {
        link: {
          href: '/reviews/car-insurance',
          slug: 'car-insurance',
        },
        title: 'Car Insurance',
      },
      {
        link: {
          href: '/reviews/home-insurance',
          slug: 'home-insurance',
        },
        title: 'Home Insurance',
      },
      {
        link: {
          href: '/reviews/mortgage-insurance',
          slug: 'mortgage-insurance',
        },
        title: 'Mortgage Insurance',
      },
      {
        link: {
          href: '/reviews/maid-insurance',
          slug: 'maid-insurance',
        },
        title: 'Maid Insurance',
      },
      {
        link: {
          href: '/reviews/pet-insurance/',
          slug: 'pet-insurance',
        },
        title: 'Pet Insurance',
      },
      {
        link: {
          href: '/reviews/long-term-endowment-plans',
          slug: 'long-term-endowment-plans',
        },
        title: 'Long Term Endowment Plans',
      },
      {
        link: {
          href: '/reviews/investment-linked-policy',
          slug: 'investment-linked-policy',
        },
        title: 'Investment Linked Policy',
      },
    ],
  },
  {
    title: 'More',
    subcategories: [
      {
        link: {
          href: '/reviews/remittance',
          slug: 'remittance',
        },
        title: 'Remittance',
      },
      {
        link: {
          href: '/reviews/buy-now-pay-later',
          slug: 'buy-now-pay-later',
        },
        title: 'Buy Now Pay Later',
      },
    ],
  },
  {
    title: 'View All',
    subcategories: [],
  },
];
