import { navbarImage, homeImage } from 'theme';

export const NAV_BAR = [
  {
    title: 'Banking',
    logo: homeImage.bankingIcon,
    subCategory: [
      {
        title: 'Savings Account',
        toolList: [
          {
            title: 'All Savings Accounts Comparison',
            link: '/reviews?categorySlug=savings-accounts',
            as: '/reviews/savings-accounts',
            imageUrl: navbarImage.savingsAcc,
          },
        ],
        guideList: [
          {
            title: 'Best for Working Adults',
            link: '/blog/best-savings-accounts-singapore',
          },
          {
            title: 'Best for Students',
            link: '/blog/best-student-savings-account',
          },
        ],
        discussionList: [],
        column: 1,
      },
      {
        title: 'Mortage & Loans',
        toolList: [
          {
            title: 'All Personal Loans Comparison',
            link: '/reviews?categorySlug=personal-loans',
            as: '/reviews/personal-loans',
            imageUrl: navbarImage.personalLoan,
          },
        ],
        guideList: [
          {
            title: 'Renovation Loans',
            link: '/blog/renovation-loan-interest-rate-singapore',
          },
          {
            title: 'Home/Mortgage Loans',
            link:
              '/blog/compilation-mortgage-housing-loans-hdb-private-singapore',
          },
        ],
        discussionList: [],
        column: 1,
      },
    ],
  },
  {
    title: 'Cards',
    logo: homeImage.cardsIcon,
    subCategory: [
      {
        title: 'Credit Cards',
        toolList: [
          {
            title: 'All Credit Cards Comparison',
            link: '/reviews?categorySlug=credit-cards',
            as: '/reviews/credit-cards',
            imageUrl: navbarImage.creditCard,
          },
          {
            title: 'All Cashback Credit Cards Comparison',
            link: '/reviews?categorySlug=credit-cards&cardTypes=Cashback',
            as: '/reviews/credit-cards/cashback',
            imageUrl: navbarImage.cashbackCard,
          },
          {
            title: 'All Miles Credit Cards Comparison',
            link: '/reviews?categorySlug=credit-cards&cardTypes=Miles',
            as: '/reviews/credit-cards/miles',
            imageUrl: navbarImage.milesCard,
          },
        ],
        guideList: [
          {
            title: 'How to Choose Your First Credit Card',
            link: '/blog/ultimate-guide-apply-credit-card-singapore',
          },
          {
            title: 'Credit Card Promo for Petrol',
            link:
              '/blog/ultimate-comparison-petrol-station-prices-best-credit-card-fuel-promotions-singapore-sinopec-shell-esso-caltex-spc',
          },
        ],
        column: 2,
      },
      {
        title: 'Debit Cards',
        toolList: [
          {
            title: 'All Debit Cards Comparison',
            link: '/reviews?categorySlug=debit-cards',
            as: '/reviews/debit-cards',
            imageUrl: navbarImage.debitCard,
          },
        ],
        guideList: [],
        discussionList: [],
        column: 1,
      },
      {
        title: 'Multi-Currency Cards',
        toolList: [
          {
            title: 'All Multi-Currency Cards Comparison',
            link: '/reviews?categorySlug=multi-currency-cards',
            as: '/reviews/multi-currency-cards',
            imageUrl: navbarImage.multiCurrencyCard,
          },
        ],
        guideList: [],
        discussionList: [],
        column: 1,
      },
    ],
  },
  {
    title: 'Investments',
    logo: homeImage.investmentsIcon,
    subCategory: [
      {
        title: 'Beginner Investing',
        toolList: [
          {
            title: 'All Brokerage Accounts Comparison',
            link: '/reviews?categorySlug=online-brokerages',
            as: '/reviews/online-brokerages',
            imageUrl: navbarImage.brokerageAcc,
          },
          {
            title: 'All Robo Advisors Comparison',
            link: '/reviews?categorySlug=robo-advisors',
            as: '/reviews/robo-advisors',
            imageUrl: navbarImage.roboAdvisor,
          },
          {
            title: 'All Investment Courses Comparison',
            link: '/reviews?categorySlug=investment-courses',
            as: '/reviews/investment-courses',
            imageUrl: navbarImage.investCourse,
          },
        ],
        guideList: [
          {
            title: 'Investments 101',
            link:
              '/blog/ultimate-personal-finance-guide-to-investing-singapore',
          },
          {
            title: 'Opening CDP & Brokerage Account',
            link:
              '/blog/step-step-guide-opening-stock-trading-brokerage-cdp-account-singapore',
          },
          {
            title: 'Intro to Investing in Robo Advisors',
            link: '/blog/guide-to-robo-advisors-singapore-comic',
          },
          {
            title: 'Investment Terms Glossary',
            link: '/blog/category/what-is',
          },
        ],
        column: 2,
      },
      {
        title: 'Advanced Investing',
        toolList: [
          {
            title: 'All P2P Lending Comparison',
            link: '/reviews?categorySlug=p2p-lending',
            as: '/reviews/p2p-lending',
            imageUrl: navbarImage.p2pLending,
          },
        ],
        guideList: [
          {
            title: 'Seedly Stocks Analysis',
            link: '/blog/category/stocks-analysis',
          },
          {
            title: 'Mutual Funds & ETFs',
            link: '/blog/category/mutual-funds-etfs',
          },
          {
            title: 'Bonds',
            link: '/blog/category/bonds',
          },
          {
            title: 'Alternative Investments',
            link: '/blog/category/alternative-investments',
          },
        ],
        column: 2,
      },
    ],
  },
  {
    title: 'Utilities & Bills',
    logo: homeImage.utilitiesIcon,
    subCategory: [
      {
        title: 'Open Electricity Market',
        toolList: [
          {
            title: 'All Electricity Retailers Comparison',
            link: '/reviews?categorySlug=open-electricity-market',
            as: '/reviews/open-electricity-market',
            imageUrl: navbarImage.electricRetail,
          },
        ],
        guideList: [
          {
            title: 'Ultimate Guide to OEM',
            link: '/blog/electricity-retailer-comparison-cheapest-in-singapore',
          },
          {
            title: 'Latest Promotions',
            link: '/blog/open-electricity-market-promo-codes-singapore',
          },
          {
            title: 'Hidden Fees & Charges',
            link: '/blog/oem-electricity-retailer-terms-and-conditions',
          },
        ],
        discussionList: [],
        column: 1,
      },
      {
        title: 'Mobile Plans',
        toolList: [
          {
            title: 'All SIM Only Mobile Plans Comparison',
            link: '/reviews?categorySlug=sim-only-mobile-plans',
            as: '/reviews/sim-only-mobile-plans',
            imageUrl: navbarImage.mobile,
          },
        ],
        guideList: [
          {
            title: 'Cheapest Roaming Plans',
            link:
              '/blog/data-roaming-travel-guide-singaporean-m1-singtel-starhub-circles-life-myrepublic',
          },
        ],
        discussionList: [],
        column: 1,
      },
      {
        title: 'Broadbands',
        toolList: [
          {
            title: 'All Broadband Comparison',
            link: '/reviews?categorySlug=broadband',
            as: '/reviews/broadband',
            imageUrl: navbarImage.broadband,
          },
        ],
        guideList: [
          {
            title: 'Cheapest Broadband Plans',
            link: '/blog/best-broadband-plan',
          },
        ],
        discussionList: [],
        column: 1,
      },
    ],
  },
  {
    title: 'Insurance',
    logo: homeImage.insuranceIcon,
    subCategory: [
      {
        title: 'Travel Insurance',
        toolList: [
          {
            title: 'All Travel Insurance Comparison',
            link: '/reviews?categorySlug=travel-insurance',
            as: '/reviews/travel-insurance',
            imageUrl: navbarImage.travel,
          },
        ],
        guideList: [
          {
            title: 'Travel Insurance Guide',
            link: '/blog/best-travel-insurance-singapore',
          },
        ],
        discussionList: [],
        column: 1,
      },
      {
        title: 'Personal Insurance',
        toolList: [
          {
            title: 'All Health Insurance Comparison',
            link: '/reviews?categorySlug=health-insurance',
            as: '/reviews/health-insurance',
            imageUrl: navbarImage.health,
          },
          {
            title: 'All Personal Accident Insurance Comparison',
            link: '/reviews?categorySlug=personal-accident-insurance',
            as: '/reviews/personal-accident-insurance',
            imageUrl: navbarImage.personalAccident,
          },
          {
            title: 'All Life Insurance Comparison',
            link: '/reviews?categorySlug=life-insurance',
            as: '/reviews/life-insurance',
            imageUrl: navbarImage.life,
          },
          {
            title: 'Disability Insurance Comparison',
            link: '/reviews?categorySlug=disability-insurance',
            as: '/reviews/disability-insurance',
            imageUrl: navbarImage.longTermEndowment,
          },
          {
            title: 'Cancer Insurance Comparison',
            link: '/reviews?categorySlug=cancer-insurance',
            as: '/reviews/cancer-insurance',
            imageUrl: navbarImage.cancerInsurance,
          },
          {
            title: 'Insurance Savings Plans Comparison',
            link: '/reviews?categorySlug=insurance-savings-plans',
            as: '/reviews/insurance-savings-plans',
            imageUrl: navbarImage.insuranceSavings,
          },
        ],
        column: 2,
      },
      {
        title: 'Other Insurance',
        toolList: [
          {
            title: 'All Car Insurance Comparison',
            link: '/reviews?categorySlug=car-insurance',
            as: '/reviews/car-insurance',
            imageUrl: navbarImage.car,
          },
        ],
        guideList: [],
        discussionList: [],
        column: 1,
      },
    ],
  },
];

export const NAV_BAR_MORE = {
  title: 'More',
  logo: homeImage.moreIcon,
  subCategory: [
    {
      title: 'Remittance',
      toolList: [
        {
          title: 'Compare All Remittance',
          link: '/reviews?categorySlug=remittance',
          as: '/reviews/remittance',
          imageUrl: navbarImage.remittance,
        },
      ],
      guideList: [],
      discussionList: [],
      column: 1,
    },
  ],
};

export const MOBILE_TOP_NAV = {
  COMMUNITY_MENU: 'community-menu',
  BLOG_MENU: 'blog-menu',
  REVIEW_MENU: 'review-menu',
  MORE_MENU: 'more-menu',
};

export const NAV_BAR_CATEGORY_TITLE = {
  COMMUNITY: 'EXPLORE COMMUNITY',
  MY_GROUPS: 'MY GROUPS',
  TRENDING_GROUPS: 'TRENDING GROUPS',
  FOLLOWED_TOPICS: 'TOPICS YOU FOLLOWED',
  ULTIMATE_GUIDES: 'ULTIMATE GUIDES',
  READ: 'READ',
};
