import clsx from 'clsx';
import React from 'react';

interface OwnProps {
  icon?: React.ReactNode;
  name: string;
  unreadCount?: number;
  isActive: boolean;
}

const ListWrapper = (props: OwnProps) => {
  const { icon, name, unreadCount, isActive } = props;

  return (
    <div
      className={clsx(
        'flex items-center justify-between py-3 px-4 w-full ',
        isActive
          ? 'bg-neutral-700 hover:bg-neutral-700 text-white'
          : 'bg-white  hover:bg-blue-100',
      )}
    >
      <div className="flex items-center gap-1">
        {icon}
        <p
          className={clsx(
            'w-[140px] text-ellipsis whitespace-nowrap overflow-hidden',
            isActive && 'font-bold text-neutral-100',
          )}
        >
          {name}
        </p>
      </div>
      {unreadCount > 0 && <p className="text-red-500">{unreadCount}</p>}
    </div>
  );
};

export default ListWrapper;
