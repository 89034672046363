import React, { useState } from 'react';
import { useSelect } from 'downshift';
import { ChevronDown } from 'react-feather';
import { Space } from '@component-lib';
import colors from 'seedly-component-library/themes/colors';
import * as S from './styles';
import Text from '../text';

const ImageIconComponent = ({
  isActive,
  isHover,
  imageData,
  handleImageClick,
}) => {
  const { filledSVG, text, hoverSVG, normalSVG } = imageData;
  const isHoverSVG = isHover ? hoverSVG : normalSVG;
  const arrowColor = isActive || isHover ? colors.seedlyBlue : colors.neutral7;

  return (
    <S.LinkContainer size="xs" isActive={isActive} onClick={handleImageClick}>
      {isActive ? filledSVG : isHoverSVG}
      <Space size="xxs">
        <Text>{text}</Text>
        <ChevronDown size={16} color={arrowColor} />
      </Space>
    </S.LinkContainer>
  );
};

const Dropdown = props => {
  const {
    items = [],
    List,
    handleSelectedItemchange = () => {},
    button,
    menuAlign = 'right',
    isActive,
    imageData,
    handleImageClick = () => {},
    testId = 'dropdown',
    isHoverOpen = false,
  } = props;

  const [isHover, setIsHover] = useState(false);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    closeMenu,
    openMenu,
  } = useSelect({
    items,
    onSelectedItemChange: handleSelectedItemchange,
  });

  const renderButton = button || (
    <ImageIconComponent
      isActive={isActive}
      isHover={isHover}
      imageData={imageData}
      handleImageClick={handleImageClick}
    />
  );

  const renderList =
    items.length > 0
      ? items.map((item, index) => (
          <Text
            renderAs="a"
            key={`${item.key}${index}`}
            variant="titlemd"
            intent="secondary"
            data-testid={`menu-option-${item.title}`}
            hoverable
            {...getItemProps({ item, index })}
            onClick={item.onClick}
          >
            {item.title}
          </Text>
        ))
      : List;

  return (
    <S.DropdownWrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => {
        setIsHover(false);
        if (isHoverOpen) closeMenu();
      }}
    >
      <S.ButtonWrapper
        {...getToggleButtonProps({
          onMouseEnter() {
            if (isHoverOpen) openMenu();
          },
        })}
        {...(isHoverOpen && { onClick: e => e.preventDefault() })}
      >
        {renderButton}
      </S.ButtonWrapper>
      <S.StyledList
        menuAlign={menuAlign}
        {...getMenuProps()}
        onClick={() => closeMenu()}
        showList={isOpen}
        data-testid={testId}
      >
        {renderList}
      </S.StyledList>
    </S.DropdownWrapper>
  );
};

export default Dropdown;
