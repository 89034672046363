import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { Text } from '@component-lib';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import {
  useSelectLatestCampaigns,
  useSelectOtherCampaigns,
} from 'entity/navigation/navigationHooks';

const Title = ({ emoji, title, label }) => {
  return (
    <SectionTitle variant="titlesm" intent="secondary">
      <span role="img" aria-label={label}>
        {emoji}{' '}
      </span>
      {title}
    </SectionTitle>
  );
};

const SectionLinks = ({ items, onItemClick }) => {
  return items.map(item => {
    const formatTitle = item.title.toLowerCase().replace(/\s/g, '_');
    return (
      <LinkWrapper
        key={item.link}
        onClick={() => {
          onItemClick();
          mixpanel.track(trackedEvents.NavbarDropdownClicked, {
            param: `more-${formatTitle}`,
          });
        }}
      >
        <Link href={item.link}>
          <Text intent="tertiary">{item.title}</Text>
        </Link>
      </LinkWrapper>
    );
  });
};

const MoreMenu = props => {
  const { onItemClick } = props;
  const happeningItems = useSelectLatestCampaigns();
  const othersItems = useSelectOtherCampaigns();

  return (
    <>
      {happeningItems.length > 0 && (
        <section>
          <Title emoji="🔥" title="LATEST" label="fire" />
          <SectionLinks items={happeningItems} onItemClick={onItemClick} />
        </section>
      )}
      <section>
        <Title emoji="👀" title="OTHERS" label="eyes" />
        <SectionLinks items={othersItems} onItemClick={onItemClick} />
      </section>
    </>
  );
};

export default MoreMenu;

const SectionTitle = styled(Text)`
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.md}`};
  background-color: ${props => props.theme.colors.neutral2};
`;

const LinkWrapper = styled.div`
  padding: ${props => `${props.theme.spacing.none} ${props.theme.spacing.md}`};
  p {
    padding: ${props => `${props.theme.spacing.sm} ${props.theme.spacing.md}`};
    padding-left: ${props => props.theme.spacing.none};
  }
`;
