import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import CommunityTab from './tabs/CommunityTab';
import BlogTab from './tabs/BlogTab';
import MoreTab from './tabs/MoreTab';
import ReviewTab from './tabs/ReviewTab';

const NavigationTab = () => {
  return (
    <NavigationMenu.Root delayDuration={0}>
      <NavigationMenu.List className="flex">
        <CommunityTab />
        <BlogTab />
        <ReviewTab />
        <MoreTab />
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};

export default NavigationTab;
