import React, { useContext, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Router from 'next/router';
import { Edit3 as Pencil, Gift } from 'react-feather';
import get from 'lodash/get';
import { Dropdown, Tooltip } from '@component-lib';
import Avatar from 'app/seedly-component-library/avatar-new';
import Button from 'app/seedly-component-library/button-new';
import Notifications from 'app/components/misc/Notification';
import { getIsOpinionWriter } from 'app/reducer/profileRedux';
import { PathContext } from 'app/context';
import buildConfig from 'app/utils/buildConfig';
import { signOut } from 'app/entity/auth/authDispatch';
import {
  openLoginModal,
  openPostModal,
  openRewardLaunchModal,
} from 'app/dispatcher/modalDispatcher';

import useIsMobile from 'app/utils/hooks/useIsMobile';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import { LOGIN_MODAL, TYPES } from 'app/constants/login';
import RewardNavbarDropdown from 'components/reward/RewardNavbarDropdown';
import { useIsEnrolledUser } from 'entity/user/userHooks';
import { rewardOnboarding } from 'theme';
import useClickOutside from 'app/utils/hooks/useClickOutside';
import Image from 'next/image';
import { RootState } from 'app/reducer';

const handleViewProfile = (host, userProfileSlug) => {
  const profilePath = `profile/${userProfileSlug}`;
  if (host.indexOf('blog') !== -1) {
    window.location.href = `${buildConfig.apiHost}/${profilePath}`;
  } else {
    Router.push(`/profile?slug=${userProfileSlug}`, `/${profilePath}`);
  }
};

const handleViewSettings = host => {
  if (host.indexOf('blog') !== -1) {
    window.location.href = `${buildConfig.apiHost}/settings`;
  } else {
    Router.push('/settings');
  }
};

const UserTab = () => {
  const [openRewardDropdown, setOpenRewardDropdown] = useState(false);

  const rewardWrapper = useRef(null);
  const isEnrolled = useIsEnrolledUser();

  useClickOutside(rewardWrapper, () => setOpenRewardDropdown(false));

  const isOpinionWriter = useSelector(getIsOpinionWriter);
  const userProfileSlug = useSelector((state: RootState) =>
    get(state.auth.user, 'slug', null),
  );
  const userProfileName = useSelector((state: RootState) =>
    get(state.auth.user, 'name', null),
  );
  const userProfileImageUrl = useSelector((state: RootState) =>
    get(state.auth.user, 'image', null),
  );
  const { host } = useContext(PathContext);
  const isSignedIn = useSelector(
    (state: RootState) => state.auth.session.isSignedIn,
  );
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const items = [
    {
      title: 'Profile',
      key: 'profile',
      onClick: () => handleViewProfile(host, userProfileSlug),
    },
    {
      title: 'Rewards',
      key: 'rewards',
      onClick: () => Router.push(`${buildConfig.apiHost}/rewards`),
    },
    {
      title: 'Saved',
      key: 'saved',
      onClick: () => {
        window.scrollTo(0, 0);
        Router.push(`${buildConfig.apiHost}/saved`);
      },
    },
    {
      title: 'Settings',
      key: 'settings',
      onClick: () => handleViewSettings(host),
    },
    {
      title: 'Sign Out',
      key: 'sign-out',
      onClick: () => {
        dispatch(signOut({ host }));
      },
    },
  ];

  if (isOpinionWriter) {
    items.splice(
      1,
      0,
      {
        title: 'Post Opinion',
        key: 'opinion',
        onClick: () => {
          window.scrollTo(0, 0);
          Router.push('/post-opinion');
        },
      },
      {
        title: 'Drafts',
        key: 'drafts',
        onClick: () => {
          window.scrollTo(0, 0);
          Router.push(`${buildConfig.apiHost}/drafts`);
        },
      },
    );
  }
  return (
    <>
      {!isMobile && (
        <Tooltip
          triggerStyle={{ display: 'inline-block' }}
          placement="top"
          renderTriggerButton={() => (
            <Pencil
              onClick={() => {
                mixpanel.track(trackedEvents.NavbarClicked, {
                  param: 'start_a_discussion',
                });
                mixpanel.track(trackedEvents.DiscussionClicked, {
                  location: 'navbar',
                });
                dispatch(openPostModal());
              }}
              style={{ cursor: 'pointer' }}
              data-testid="post-icon"
            />
          )}
          showToolTip
          renderToolTipBody={() => <div>Start a discussion</div>}
        />
      )}
      <div className="relative pt-1" ref={rewardWrapper}>
        {isEnrolled ? (
          <Gift
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenRewardDropdown(!openRewardDropdown);
            }}
          />
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(openRewardLaunchModal());
            }}
          >
            <Image src={rewardOnboarding.giftSmall} width={32} height={32} />
          </div>
        )}
        {openRewardDropdown && <RewardNavbarDropdown />}
      </div>
      {isSignedIn ? (
        <>
          <Notifications />
          <Dropdown
            items={items}
            handleSelectedItemchange={item => {
              item.selectedItem.onClick();
            }}
            button={
              <div className="mt-1">
                <Avatar name={userProfileName} src={userProfileImageUrl} />
              </div>
            }
          />
        </>
      ) : (
        <div className="flex gap-2 items-center">
          <Button
            data-testid="join-button"
            variant="outline"
            onClick={() => {
              mixpanel.track(trackedEvents.LoginSignUpModal, {
                param: 'login',
              });
              dispatch(openLoginModal(LOGIN_MODAL[TYPES.LOGIN]));
            }}
          >
            Login
          </Button>
          {!isMobile && (
            <Button
              variant="primary"
              onClick={() => {
                mixpanel.track(trackedEvents.LoginSignUpModal, {
                  param: 'sign_up',
                });
                dispatch(openLoginModal(LOGIN_MODAL[TYPES.SIGNUP]));
              }}
            >
              Sign Up
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default UserTab;
