import React, { useEffect, useState } from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';

const NavigationContent = props => {
  const { children, className } = props;

  const [forceMount, setForceMount] = useState(true);

  useEffect(() => {
    setForceMount(false);
  }, []);

  return forceMount ? (
    <NavigationMenu.Content
      className={clsx(
        'absolute mt-2.5 py-1 top-full bg-white rounded-lg z-10 shadow-xl hidden',
        className,
      )}
      forceMount
    >
      {children}
    </NavigationMenu.Content>
  ) : (
    <NavigationMenu.Content
      className={clsx(
        'absolute mt-2.5 py-1 top-full bg-white rounded-lg z-10 shadow-xl',
        className,
      )}
    >
      {children}
    </NavigationMenu.Content>
  );
};

export default NavigationContent;
