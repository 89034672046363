import React from 'react';
import { useRouter } from 'next/router';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import NavigationTrigger from '../wrapper/NavigationTrigger';
import NavigationContent from '../wrapper/NavigationContent';
import NavMoreList from '../../NavList/NavMoreList';

const MoreTab = () => {
  const slug = [
    '/campaignDetail',
    '/events/personal-finance-festival-2020',
    '/rewards',
  ];
  const router = useRouter();
  const isActive = slug.includes(router.pathname);

  return (
    <NavigationMenu.Item>
      <NavigationTrigger isActive={isActive} title="More" />
      <NavigationContent>
        <NavMoreList />
      </NavigationContent>
    </NavigationMenu.Item>
  );
};

export default MoreTab;
