import React, { useEffect, useState } from 'react';
import { ChevronRight } from 'react-feather';
import Link from 'app/components/misc/Link';
import reviews from 'app/constants/reviews';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import addTrailingSlash from 'app/utils/addTrailingSlash';
import analytics from 'app/utils/analytics';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useRouter } from 'next/router';
import clsx from 'clsx';

const NavReviewItem = props => {
  const router = useRouter();
  const { items } = props;
  const { subcategories = [], title } = items;
  const [isActive, setIsActive] = useState(false);
  const formatTitleMixpanel = title.toLowerCase().replace(/\s/g, '_');

  useEffect(() => {
    let highlight = false;
    subcategories.forEach(item => {
      if (addTrailingSlash(item.link.href) === router.asPath) {
        highlight = true;
      }
    });
    setIsActive(highlight);
  }, [subcategories, router.asPath]);

  return (
    <NavigationMenu.Item className="bg-white rounded-lg w-full relative">
      <NavigationMenu.Trigger
        className={clsx(
          'px-3 py-2 flex justify-between w-full items-center',
          isActive && 'bg-neutral-700',
          isActive ? 'hover:bg-neutral-700' : 'hover:bg-blue-100',
        )}
      >
        <p className={clsx(isActive && 'text-white')}>{title}</p>
        <ChevronRight
          className={clsx(isActive ? 'text-white' : 'text-neutral-700')}
          size={20}
        />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className="absolute -top-1 left-[101%] py-1 bg-white rounded-lg shadow-xl z-10">
        <div className="list gap-1 w-max">
          {subcategories.map(item => {
            const formatSlug = item.slug
              ? item.slug.toLowerCase().replace('-', '_')
              : '';
            return (
              <Link
                key={item.title}
                href={addTrailingSlash(item.link.href)}
                passHref
              >
                <NavigationMenu.Link
                  onClick={() => {
                    mixpanel.track(trackedEvents.NavbarDropdownClicked, {
                      param: `product_reviews-${formatTitleMixpanel}-${formatSlug}`,
                    });
                    analytics.trackMenuItemClick({
                      title: 'Reviews',
                      menuItemName: `${formatTitleMixpanel}-${formatSlug}`,
                    });
                  }}
                  className={clsx(
                    'flex items-center px-3 py-2 gap-4  w-full',
                    addTrailingSlash(item.link.href) === router.asPath
                      ? 'bg-neutral-700 hover:bg-neutral-700 text-white'
                      : 'hover:bg-blue-100',
                  )}
                >
                  {item.title}
                </NavigationMenu.Link>
              </Link>
            );
          })}
        </div>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
};

const NavReviewList = () => {
  const router = useRouter();

  return (
    <NavigationMenu.List className="list">
      {reviews.map(item =>
        item.subcategories.length > 0 ? (
          <NavReviewItem key={item.title} items={item} />
        ) : null,
      )}
      <Link href="/reviews/sign-up-bonus/" passHref>
        <a
          onClick={() => {
            mixpanel.track(trackedEvents.NavbarDropdownClicked, {
              param: 'product_reviews-sign_up_bonus',
            });
            analytics.trackMenuItemClick({
              title: 'Reviews',
              menuItemName: 'sign-up-bonus',
            });
          }}
          className={clsx(
            'flex items-center px-3 py-2 gap-4 hover:bg-blue-100 w-full',
            router.asPath === '/reviews/sign-up-bonus/' &&
              'bg-neutral-700 hover:bg-blue-700 text-white',
          )}
        >
          Sign Up Bonus
        </a>
      </Link>
      <Link href="/reviews/" passHref>
        <a
          onClick={() => {
            mixpanel.track(trackedEvents.NavbarDropdownClicked, {
              param: 'product_reviews-view_all',
            });
            analytics.trackMenuItemClick({
              title: 'Reviews',
              menuItemName: 'view-all',
            });
          }}
          className={clsx(
            'flex items-center px-3 py-2 gap-4 hover:bg-blue-100 w-full',
            router.asPath === '/reviews/' &&
              'bg-neutral-700 hover:bg-blue-700 text-white',
          )}
        >
          View All
        </a>
      </Link>
    </NavigationMenu.List>
  );
};

export default NavReviewList;
