import React from 'react';
import { useRouter } from 'next/router';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import Review from 'seedly-component-library/icons/review.svg';
import ReviewFilled from 'seedly-component-library/icons/review-filled.svg';
import NavigationTrigger from '../wrapper/NavigationTrigger';
import NavigationContent from '../wrapper/NavigationContent';
import NavReviewList from '../../NavList/NavReviewList';

const ReviewTab = () => {
  const router = useRouter();
  const isActive = router.asPath.includes('/reviews/');
  return (
    <NavigationMenu.Item>
      <NavigationTrigger
        isActive={isActive}
        icon={<Review className="fill-neutral-700" />}
        hoverIcon={<Review className="fill-blue-500" />}
        activeIcon={<ReviewFilled />}
        title="Product Reviews"
      />
      <NavigationContent className="w-[200px]">
        <NavigationMenu.Sub className="w-full">
          <NavReviewList />
        </NavigationMenu.Sub>
      </NavigationContent>
    </NavigationMenu.Item>
  );
};

export default ReviewTab;
