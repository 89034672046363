import React from 'react';
import Link from 'next/link';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import analytics from 'app/utils/analytics';
import {
  useSelectLatestCampaigns,
  useSelectOtherCampaigns,
} from 'entity/navigation/navigationHooks';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useRouter } from 'next/router';
import clsx from 'clsx';

const MoreSubSection = ({ itemData, header }) => {
  const handleClick = (e, title) => {
    e.stopPropagation();
    mixpanel.track(trackedEvents.NavbarDropdownClicked, {
      param: `more-${title}`,
    });

    analytics.trackMenuItemClick({
      title: 'More',
      menuItemName: title,
    });
  };

  const router = useRouter();

  const dataSource = itemData.map(({ title, link }) => {
    const formatTitleMixpanel = title.toLowerCase().replace(/\s/g, '_');
    const isActive = link.includes(router.asPath) && router.asPath !== '/';

    return (
      <Link key={title} href={link} passHref>
        <NavigationMenu.Link
          onClick={event => handleClick(event, formatTitleMixpanel)}
          className={clsx(
            'flex items-center px-3 py-2 gap-4 hover:bg-blue-100 w-full',
            isActive && 'bg-neutral-700 hover:bg-blue-700 text-white',
          )}
        >
          {title}
        </NavigationMenu.Link>
      </Link>
    );
  });
  return (
    <div className="flex flex-col w-full gap-2">
      {header}
      <div className="list gap-1">{dataSource}</div>
    </div>
  );
};

const NavMoreList = () => {
  const happeningItems = useSelectLatestCampaigns();
  const othersItems = useSelectOtherCampaigns();

  return (
    <div className="flex flex-col gap-2 w-[296px]">
      {happeningItems.length > 0 && (
        <MoreSubSection
          itemData={happeningItems}
          header={
            <p className="pl-4 pt-4 text-neutral-500 text-xs font-bold">
              <span role="img" aria-label="fire">
                🔥
              </span>{' '}
              LATEST HAPPENINGS
            </p>
          }
        />
      )}
      <MoreSubSection
        itemData={othersItems}
        header={
          <p className="pl-4 pt-4 text-neutral-500 text-xs font-bold">
            <span role="img" aria-label="fire">
              👀
            </span>{' '}
            OTHERS
          </p>
        }
      />
    </div>
  );
};

export default NavMoreList;
