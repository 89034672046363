import React from 'react';
import Link from 'app/components/misc/Link';
import LogoSVG from './SVG';

interface OwnProps {
  height?: number;
  color?: string;
}

const Logo = ({ height, color }: OwnProps) => {
  return (
    <Link homepage href="/" as="/">
      <a style={{ height }}>
        <LogoSVG height={height} width={90} color={color} />
      </a>
    </Link>
  );
};

export default Logo;
