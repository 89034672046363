import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  padding: 16px;
  background-color: #ffffff;
  font-size: 12px;
`;

const DefaultAccordionItemPanel = ({ item }) => {
  return (
    <Container>
      <div>{item.description}</div>
    </Container>
  );
};

DefaultAccordionItemPanel.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DefaultAccordionItemPanel;
