import React from 'react';
import { RootState } from 'reducer';
import { useSelector, useDispatch } from 'react-redux';
import { TierMap, TierColorMap } from 'entity/reward/rewardTypes';
import { Button } from 'seedly-component-library';
import { openDailyCheckInRewardModal } from 'dispatcher/modalDispatcher';
import { Gift } from 'react-feather';
import Image from 'next/image';
import buildConfig from 'utils/buildConfig';
import { useRouter } from 'next/router';
import { rewardOnboarding, media } from 'theme';
import styled from 'styled-components';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import TierColorContainer from '../TierColorContainer';

const StyledDropdownCard = styled.div<{ bgColor: string }>`
  position: absolute;
  transform: translateX(-50%);
  top: 42px;
  border-radius: ${props => props.theme.borderRadius.lg};
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 20%);
  z-index: 10;

  /* CSS for the triangle pointer */
  ::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-bottom: 8px solid ${props => props.bgColor};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    top: -8px;
    left: 51%;
  }

  ${media.largeAndDown`
    transform: translateX(-65%);

    ::before {  
    left: 66%;
  }
  `}
`;

const RewardNavbarDropdown = () => {
  const profile = useSelector((state: RootState) => state.profile.user);
  const creditsCount = profile?.creditsCount || 0;
  const tier = profile?.tier || 1;
  const router = useRouter();
  const dispatch = useDispatch();
  let creditsText = '0';

  if (creditsCount >= 10000) {
    const thousands = creditsCount / 1000;
    const rounded = Math.round(thousands * 10) / 10;
    creditsText = `${rounded}K`;
  } else {
    creditsText = creditsCount?.toLocaleString();
  }

  return (
    <StyledDropdownCard bgColor={TierColorMap[tier]}>
      <TierColorContainer className="card w-[303px]" tier={tier}>
        <div className="flex flex-col w-full gap-3">
          <div className="flex w-full justify-between">
            <div className="flex gap-1 items-center">
              <Gift size={16} className="text-neutral-400" />
              <p className="text-xs font-bold uppercase text-neutral-400">
                SEEDLY REWARDS
              </p>
            </div>
            <p className="text-xs font-bold uppercase text-white">
              {TierMap[tier]}
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <Image src={rewardOnboarding.singleCoin} height={36} width={36} />
            <p className="text-[32px] font-bold text-white">
              {creditsText} Credits
            </p>
          </div>
          <div className="flex flex-col w-full gap-2">
            <Button
              onClick={() => {
                // open daily check in
                dispatch(openDailyCheckInRewardModal());
                mixpanel.track(trackedEvents.RewardsCollection, {
                  param: 'notification_daily_check_in',
                });
              }}
              variant="outlineWhite"
              size="lg"
              isBlock
            >
              Daily Check in
            </Button>
            <Button
              onClick={() => {
                router.push(`${buildConfig.apiHost}/rewards`);
                mixpanel.track(trackedEvents.RewardsCollection, {
                  param: 'notification_go_to_rewards',
                });
              }}
              variant="primaryWhite"
              size="lg"
              isBlock
            >
              Go to Rewards
            </Button>
          </div>
        </div>
      </TierColorContainer>
    </StyledDropdownCard>
  );
};

export default RewardNavbarDropdown;
