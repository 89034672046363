import React from 'react';
import Logo from '../Logo';

const AmpHeader = () => {
  return (
    <>
      <div id="back-to-top" />
      <div className="flex items-center justify-center w-full h-[56px] bg-white shadow ">
        <Logo />
      </div>
    </>
  );
};

export default AmpHeader;
