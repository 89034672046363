import React from 'react';
import take from 'lodash/take';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { isNew } from 'entity/notification/notificationUtils';
import Footer from './Footer';
import SubHeader from './SubHeader';
import Item from './Item';
import Empty from './Empty';

const List = ({ notification, checkedAt }) => {
  if (isEmpty(notification.data)) {
    return <Empty />;
  }

  const takeList = take(notification.data, 20);
  const uncheckedList = takeList.filter(notificationItem =>
    isNew(notificationItem, checkedAt),
  );
  const checkedList = takeList.filter(
    notificationItem => !includes(uncheckedList, notificationItem),
  );

  return (
    <>
      <div
        className="overflow-y-scroll max-h-[400px]"
        data-testid="notif-desktop-list"
      >
        {!isEmpty(uncheckedList) && <SubHeader subTitle="NEW" />}
        {uncheckedList.map((notificationItem: { id: number }) => (
          <Item
            key={notificationItem.id}
            notification={notificationItem}
            theme="default"
          />
        ))}
        {!isEmpty(checkedList) && <SubHeader subTitle="EARLIER" />}
        {checkedList.map((notificationItem: { id: number }) => (
          <Item
            key={notificationItem.id}
            notification={notificationItem}
            theme="default"
          />
        ))}
      </div>
      <Footer visible={notification.data.length > 5}>See All</Footer>
    </>
  );
};

export default List;
