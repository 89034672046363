import React from 'react';
import { useRouter } from 'next/router';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import Community from 'seedly-component-library/icons/community.svg';
import CommunityFilled from 'seedly-component-library/icons/community-filled.svg';
import NavFeedList from '../../NavList/NavFeedList';
import NavGroupList from '../../NavList/NavGroupList';
import NavigationTrigger from '../wrapper/NavigationTrigger';
import NavigationContent from '../wrapper/NavigationContent';

const CommunityTab = () => {
  const slug = '/community/[[...slug]]';
  const router = useRouter();
  const isActive = router.pathname === slug;

  return (
    <NavigationMenu.Item>
      <NavigationTrigger
        isActive={isActive}
        icon={<Community className="fill-neutral-700" />}
        hoverIcon={<Community className="fill-blue-500" />}
        activeIcon={<CommunityFilled />}
        title="Community"
      />
      <NavigationContent className="w-[296px] h-[576px] overflow-y-scroll">
        <NavFeedList />
        <NavGroupList />
      </NavigationContent>
    </NavigationMenu.Item>
  );
};

export default CommunityTab;
