import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import useFeedList from 'pageContents/CommunityContent/hooks/useFeedList';
import { CommunityPageContext } from 'pageContents/CommunityContent/context/CommunityPageContext';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import analytics from 'app/utils/analytics';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import Link from 'components/misc/Link';
import ListWrapper from './ListWrapper';

const NavFeedList = ({ onItemClick = () => {}, isMobile = false }) => {
  const { pageType, setPageType } = useContext(CommunityPageContext);
  const feedList = useFeedList();
  const router = useRouter();

  const dataSource = feedList.map(item => {
    const isActive =
      pageType === item.value && router.pathname.includes('/community');

    return (
      <div
        key={item.value}
        onClick={() => {
          mixpanel.track(trackedEvents.NavbarDropdownClicked, {
            param: `${item.value}`,
          });
          analytics.trackMenuItemClick({
            title: 'Community',
            menuItemName: item.value,
          });
          onItemClick();
          setPageType(item.value);
        }}
        className="w-full"
      >
        <Link href={`/community/${item.query ? item.query : ''}`} passHref>
          {isMobile ? (
            <a>
              <ListWrapper
                icon={item.icon}
                isActive={isActive}
                name={item.name}
              />
            </a>
          ) : (
            <NavigationMenu.Link>
              <ListWrapper
                icon={item.icon}
                isActive={isActive}
                name={item.name}
              />
            </NavigationMenu.Link>
          )}
        </Link>
      </div>
    );
  });

  return (
    <div className="flex flex-col w-full gap-2">
      {isMobile ? null : (
        <p className="pl-4 pt-4 text-neutral-500 text-xs font-bold">FEEDS</p>
      )}
      <div className="list gap-1">{dataSource}</div>
    </div>
  );
};

export default NavFeedList;
