import React from 'react';
import { useIsSignedIn } from 'app/entity/user/userHooks';

import NavGroupList from '../../NavList/NavGroupList';
import NavFeedList from '../../NavList/NavFeedList';

const CommunityMenu = props => {
  const { onCloseClick } = props;
  const isSignedIn = useIsSignedIn();
  const groupTitle = isSignedIn ? 'MY GROUPS' : 'TRENDING GROUPS';

  return (
    <>
      <section>
        <p className="pl-4 pt-4 text-neutral-500 text-xs font-bold">FEEDS</p>
        <NavFeedList onItemClick={onCloseClick} isMobile />
      </section>
      <section>
        <p className="pl-4 pt-4 text-neutral-500 text-xs font-bold">
          {groupTitle}
        </p>
        <NavGroupList onItemClick={onCloseClick} isMobile />
      </section>
    </>
  );
};

export default CommunityMenu;
