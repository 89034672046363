import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { Search } from 'react-feather';
import useIsMobile from 'app/utils/hooks/useIsMobile';
import { SearchContext } from 'app/components/search/SearchProvider';

import { useRouter } from 'next/router';
import AmpHeader from './AmpHeader';
import MobileMenu from './MobileMenu';
import NavigationTab from './NavigationTab';
import UserTab from './UserTab';
import Logo from './Logo';

const DesktopSearchBar = dynamic(
  () => import('app/components/search/DesktopSearchBar'),
);
const MobileSearchBar = dynamic(
  () => import('app/components/search/MobileSearchBar'),
);

const DefaultHeader = props => {
  const { renderSubHeader = () => {}, forwardRef } = props;

  const { showMobileResult, setShowMobileResult } = useContext(SearchContext);

  const isMobile = useIsMobile();
  const router = useRouter();

  const { route } = router;
  const hideSearch = route === '/' || route === '/search';

  return (
    <div
      className="items-center flex  flex-col justify-center fixed left-0 top-0 w-full z-10 bg-white transition duration-300 ease-out shadow"
      ref={forwardRef}
    >
      <nav className="h-[56px] my-0 mx-auto z-10 px-3 lg:px-0 w-full lg:w-auto">
        <>
          {isMobile ? (
            <div className="flex justify-between w-full h-full items-center">
              {showMobileResult ? (
                <MobileSearchBar />
              ) : (
                <>
                  <div className="flex items-center">
                    <MobileMenu />
                    <Logo height={24} />
                  </div>
                  <div className="flex gap-3 items-center">
                    {!hideSearch && (
                      <Search
                        height="24"
                        width="24"
                        onClick={() => setShowMobileResult(true)}
                      />
                    )}
                    <UserTab />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="flex h-full items-center gap-4">
              <Logo height={32} />
              {hideSearch ? (
                <div className="w-[400px]" />
              ) : (
                <DesktopSearchBar />
              )}
              <NavigationTab />
              <div className="vertical-divider h-6" />
              <UserTab />
            </div>
          )}
        </>
      </nav>
      <div className="bg-transparent w-full">{renderSubHeader()}</div>
    </div>
  );
};

const Header = DefaultHeader;

export { AmpHeader, Header };
